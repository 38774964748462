import React from "react";
import { useAuthUser, withAuthUser } from "next-firebase-auth";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Head from "next/head";
//import Link from 'next/link';
import { Row, Container, Col } from "reactstrap";

const Custom404 = () => {
  const AuthUser = useAuthUser();
  return (
    <>
      <Head>
        <title>Page not found</title>
        <meta key="description" name="description" content="Page not found" />
        <meta
          key="ogdescription"
          property="og:description"
          content="Page not found"
        />
      </Head>
      <Header />
      <Container>
        <Row sm="1" md="3">
          <Col md="3" className="d-none d-md-block"></Col>
          <Col md="6" sm="12">
            <h1>Page not found</h1>
          </Col>
          <Col md="3" className="d-none d-md-block"></Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default withAuthUser()(Custom404);
